<template lang="pug">
  .login
    .login__top
      a.login__top-arrow(href="//kickex.com", v-if="!showCaptchaDialog && !showCodeInput")
        SvgArrowLeft
      .login__top-arrow(@click="goSignIn", v-else)
        SvgArrowLeft
      .login__top-route {{ $t('authTitles.suggestReg') }}
        router-link(:to="{ name: 'signup' }") {{ $t('authTitles.signup') }}
      LanguageSwitching
    div(v-show="!showCaptchaDialog")
      .process_verification(v-if="showCodeInput")
        h2 {{ $t('hints.enterVerificationCode') }}
        SecureCodeInputs(
          v-model="code",
          :errors="errors2FA",
          :twoFAType="twoFAType",
          :email="email",
          :loginToken="loginToken",
          :blurOnComplete="true")
      .login__form(v-else)
        h1 {{ $t('authTitles.signin') }}
        .form_block
          v-form.form(ref="form", v-model="valid", @submit.prevent="")
            v-layout(column)
              .warning-notice(v-if="warningMessage")
                p {{ warningMessage }}
              .login__email
                div {{ $t('formFields.email') }}
                v-text-field#qa-input-email(
                v-model.trim="email",
                :placeholder="placeholder",
                name="email",
                :rules="[rules.email, rules.required]",
                :label="$t('formFields.email')",
                required)
              div
                .login__passwoed.d-flex.justify-space-between.align-center
                  span {{ $t('formFields.password') }}
                  .forgot_link
                    router-link(to="restore") {{ $t('actions.forgotPassword') }}
                v-text-field#qa-input-password.password(
                v-model="password",
                :placeholder="placeholder",
                :type="showPassword ? 'text' : 'password'",
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'",
                @click:append="showPassword = !showPassword",
                :rules="[rules.required, rules.passwordLength, rules.max]",
                :label="$t('formFields.password')",
                required)
                .errors(v-if="errors && errors.length")
                  .errors__item(
                    v-for="error in errors"
                    :key="error.title")
                    strong {{ $t(`errorCodes.${error.code}.title`) }}:&nbsp;
                    span(v-html="$t(`errorCodes.${error.code}.detail`)")

                v-btn#qa-btn-signin.login__button(width="100%", :disabled="!valid || loading", @click="submitForm", type="submit", data-name="landing-signin") {{ $t('actions.signIn') }}
        SocialSignIn
    CaptchaDialog(
      :visible="showCaptchaDialog",
      :loading="captchaLoading",
      :errors="errorsCaptcha",
      @clickClose="showCaptchaDialog = false",
      @submit="handleCaptchaSubmit")
</template>

<script>
import { mapState } from 'vuex';
import SocialSignIn from '@/components/SocialSignIn';
import SecureCodeInputs from '@/components/SecureCodeInputs';
import CaptchaDialog from '@/components/CaptchaDialog';
import SvgArrowLeft from '@/components/svg/SvgArrowLeft';
import REGEXP from '@/regexp';
import { SIGNIN_CAPTCHA_REQUIRED } from '@/store/modules/user/types';
import LanguageSwitching from '@/components/LanguageSwitching';

export default {
  components: {
    SocialSignIn,
    SecureCodeInputs,
    CaptchaDialog,
    SvgArrowLeft,
    LanguageSwitching
  },
  data() {
    return {
      valid: false,
      loading: false,
      captcha: '',
      showCaptchaDialog: false,
      captchaLoading: false,
      errorsCaptcha: [],
      errors: [],
      errors2FA: [],
      email: '',
      password: '',
      placeholder: '',
      showPassword: false,
      showCodeInput: false,
      loginToken: '',
      code: '',
      twoFAType: null
    };
  },
  computed: {
    ...mapState('user', [
      'captchaId',
      'acceptedLastVersions',
      'signinCaptchaRequired',
      'socialSignInErrors'
    ]),
    rules() {
      return {
        email: v => REGEXP.email.test(v.trim()) || this.$t('validation.emailInvalid'),
        required: v => !!v.trim() || this.$t('validation.required'),
        passwordLength: v => v.length >= 8 || this.$t('validation.passwordLength', { min: 8 }),
        max: v => v.length <= 160 || this.$t('validation.maxLength', { length: 160 })
      };
    },
    warningMessage() {
      if (!this.$route.query?.warning) return false;

      const text = this.$t('authTitles.warningNotice')[this.$route.query.warning];

      return text || false;
    }
  },
  mounted() {
    this.autofillFix();
    this.$store.dispatch('user/setImgName', 'signIn');
  },
  watch: {
    code(val) {
      if (val.length === 6) this.confirm2fa();
    },
    acceptedLastVersions(val) {
      if (val) this.redirectAfterSignIn();
    },
    signinCaptchaRequired(val) {
      if (val) {
        this.showCaptchaDialog = true;
        this.$store.dispatch('user/setImgName', 'captcha');
      }
    }
  },
  methods: {
    goSignIn() {
      this.showCaptchaDialog = false;
      this.showCodeInput = false;
      this.$store.dispatch('user/setImgName', 'signIn');
    },
    autofillFix() {
      if (navigator.userAgent.indexOf('AppleWebKit') >= -1 && navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
        try {
          document.querySelector('input[name="email"]:-webkit-autofill');

          let count = 0;
          const interval = setInterval(() => {
            count++;

            if (document.querySelector('input[name="email"]:-webkit-autofill')
            && document.querySelector('input[type="password"]:-webkit-autofill')) {
              this.placeholder = ' ';
              this.valid = true;
              clearInterval(interval);
            }

            if (count >= 20) clearInterval(interval);
          }, 100);
        } catch (error) {
          console.error(error);
        }
      }
    },
    submitForm() {
      this.errors = [];
      this.errorsCaptcha = [];
      this.loading = true;

      if (!this.$refs.form.validate()) return;

      const data = {
        data: {
          type: 'sessions',
          attributes: {
            email: this.email,
            password: this.password
          }
        }
      };

      if (this.signinCaptchaRequired) {
        data.data.attributes.captcha_id = this.captchaId;
        data.data.attributes.captcha_code = this.captcha;
        this.captchaLoading = true;
      }

      this.$store.dispatch('user/signinByEmail', data)
        .then(res => {
          this.errors = [];
          if (this.signinCaptchaRequired) {
            this.$store.commit(`user/${SIGNIN_CAPTCHA_REQUIRED}`, false);
            this.showCaptchaDialog = false;
            this.$store.dispatch('user/setImgName', 'signIn');
          }

          if (res.attributes.login_token) {
            this.showCodeInput = true;
            this.loginToken = res.attributes.login_token;
            this.twoFAType = res.attributes['2fa_type'];
            if (this.twoFAType === 1) {
              this.$store.dispatch('user/setImgName', 'twoFAT');
            } else {
              this.$store.dispatch('user/setImgName', 'emailConfirm');
            }
          } else {
            this.loading = true;
            this.redirectAfterSignIn();
          }
        })
        .catch(errors => {
          if (errors.message) {
            this.errors = [{ title: this.$t('messages.error.undefined'), detail: errors.message }];
            return;
          }

          const hasCaptchaError = errors.some(error => error.title.includes('captcha') || error.detail.includes('captcha'));
          const hasCaptchaIdError = errors.some(error => error.title.includes('captcha_id') || error.detail.includes('captcha_id'));

          if (!hasCaptchaError) {
            // this.errors = [{ title: errors[0].title, detail: errors[0].detail }];
            this.errors = errors;
            this.showCaptchaDialog = false;
            this.$store.dispatch('user/setImgName', 'signIn');

            return;
          }

          this.showCaptchaDialog = true;
          this.$store.dispatch('user/setImgName', 'captcha');
          if (!hasCaptchaIdError) {
            this.errorsCaptcha = errors;
          }
        })
        .finally(() => {
          if (this.signinCaptchaRequired) {
            this.captcha = '';
            this.loading = false;
            this.captchaLoading = false;
          } else {
            this.loading = false;
          }
        });
    },
    confirm2fa() {
      const payload = {
        data: {
          type: 'confirm-2-fa',
          attributes: {
            code: this.code,
            login_token: this.loginToken
          }
        }
      };

      this.$store.dispatch('user/confirm2fa', payload)
        .then(() => this.redirectAfterSignIn())
        .catch(errors => { this.errors2FA = errors; });
    },
    handleCaptchaSubmit(captcha) {
      this.captcha = captcha;
      this.submitForm();
    },
    async redirectAfterSignIn() {
      const { attributes } = await this.$store.dispatch('user/getUser');
      const serviceId = localStorage.getItem('service_id');

      if (!this.acceptedLastVersions) return;

      if (!attributes.registration_country_id || !attributes.first_name || !attributes.last_name) {
        this.$router.push({ name: 'complete-signup' });
        return;
      }

      if (serviceId && (attributes.kyc_status === 3 || attributes.is_email_confirmed)) {
        this.$store.dispatch('user/signinService', { serviceId, router: this.$router })
          .finally(() => { this.loading = false; });
      } else {
        this.$router.push('/settings/verification');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';
  .login{
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 1240px) {
      /*padding-right: 17px;*/
    }
    @media screen and (max-width: 530px) {
      max-width: 100%;
    }

    .warning-notice {
      color: $blue;
    }

    h1 {
      font-size: 36px;
      color: $black2;
      margin: 12vh 0 2vh;
      @media screen and (max-width: 530px) {
        margin: 20px 0;
        text-align: left;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
      }
    }
    &__passwoed{
      margin-bottom: 5px;
        span{
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: rgba(31, 33, 38, 0.6);
        }
      }
    &__email{
      margin-bottom: 10px;
      >div{
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: rgba(31, 33, 38, 0.6);
        text-align: left;
      }
    }
    &__button {
      background: #0071E3!important;
      border-radius: 5px;
      width: 100%;
      height: 40px !important;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 530px) {
        border-bottom: 1px solid rgba(31, 33, 38, 0.1);
        padding: 0 20px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-arrow{
        display: none;
        @media screen and (max-width: 530px) {
          display: block;
        }
      }
      &-route{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        text-align: right;
        color: #000000;
        a{
          margin-left: 5px;
          text-decoration: none;
        }
      }
    }
    &__form{
      @media screen and (max-width: 530px) {
        padding: 0 20px;
      }
    }
  }
  .switch { border-top: 1px solid $disabled-btn; }

  .forgot_link {
    text-align: left;
    font-size: 16px;
    font-weight: 600;

    a {
      color: $blue;
      text-decoration: none;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
    }
  }

  .process_verification {
    @media screen and (max-width: 530px) {
      padding: 0 20px;
    }
    h2 {
      margin-bottom: 5vh;
      margin-top: 20vh;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #1F2126;
      @media screen and (max-width: 530px) {
        margin: 20px 0;
        text-align: left;
      }
    }
  }

  .errors {
    margin-bottom: 10px;
  }

  ::v-deep .v-text-field .v-label{
    opacity: 0;
  }
  ::v-deep .v-text-field .v-input__slot{
    height: 45px;
    padding-top: 5px;
  }
  ::v-deep .password .v-input__append-inner {
    margin-top: 6px !important;
  }
  ::v-deep .v-text-field input {
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    color: #1F2126;
  }
  @media screen and (max-width: 960px) {
    ::v-deep .ofcold__security-code { text-align: center !important; }
  }

  @media screen and (max-width: 450px) {
    .process_verification { margin-bottom: 0; }
  }
</style>
